













import Vue from 'vue';
import Axios from 'axios';

export default Vue.extend({
  name: 'redirect',
  data: () => ({
    state: 'pending',
  }),
  mounted() {
    const { code } = this.$route.query;
    if (code) {
      Axios.request({
        method: 'POST',
        url: `${process.env.VUE_APP_API}twitch`,
        data: { code },
      })
        .then(() => this.state = 'success')
        .catch(() => this.state = 'error');
    } else {
      this.state = 'error';
    }
  },
});
