



















import Vue from 'vue';
import IconifyIcon from '@iconify/vue';
import twitchIcon from '@iconify/icons-simple-icons/twitch';

export default Vue.extend({
  name: 'Home',
  components: {
    IconifyIcon,
  },
  data: () => ({
    icons: {
      twitchIcon,
    },
    scopes: ['clips:edit'],
  }),
  methods: {
    register() {
      window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&response_type=code&scope=${this.scopes.join(' ')}&force_verify=true`;
    },
  },
});
